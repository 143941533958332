// Our main CSS
import '../css/app.css'

let accordion = document.querySelectorAll('.js-accordion')
accordion.forEach((item) => {
  item.addEventListener('click', () => {
    item.classList.toggle('is-active')
    item.nextElementSibling.classList.toggle('hidden')
  })
})

const hamburgerButton = document.querySelector('.js-hamburger')
const mobileNavMenu = document.querySelector('.js-mobile-nav-menu')

hamburgerButton.addEventListener('click', function () {
  this.classList.toggle('is-active')
  var isExpanded = this.getAttribute('aria-expanded') === 'true'
  this.setAttribute('aria-expanded', !isExpanded)
  mobileNavMenu.classList.toggle('is-active')
})
